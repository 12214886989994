//=================================================
// On the Hour
//=================================================

// Project specific code to run when opening (Maya Man)
import { getReducedMotion, ignoreUserPreferences } from './user-preferences';

// Returns a Date object representing the top of the current hour
function getTopOfCurrentHour(date = new Date()) {
    const topOfCurrentHour = new Date(date);
    topOfCurrentHour.setMinutes(0, 0, 0);
    return topOfCurrentHour;
}

// Checks if the current time is within the first minute of the hour
function isWithinFirstMinute(date = new Date()) {
    return date.getMinutes() === 0 && date.getSeconds() < 60;
}

// Calculate time difference in seconds between two dates
function timeDistance(a, b) {
    const newA = a instanceof Date ? a.getTime() : a;
    const newB = b instanceof Date ? b.getTime() : b;
    return Math.abs(Math.floor(newA / 1000) - Math.floor(newB / 1000)); // Return difference in seconds
}

// Checks if the stored timestamp is out of sync with the current top of the hour
function isSessionVariableOutOfSync(storedDate) {
    const topOfCurrentHour = getTopOfCurrentHour(currentDate);
    return timeDistance(storedDate, topOfCurrentHour) > 60; // Returns true if out of sync
}

// Sets a session storage variable for tracking the on-the-hour event
function setSessionVariable(currentDate) {
    const storedTimestamp = sessionStorage.getItem('on-the-hour');
    const storedDate = storedTimestamp ? new Date(storedTimestamp) : null;

    // Only set a new session variable if the stored one is out of sync or doesn't exist
    if (!storedDate || isSessionVariableOutOfSync(storedDate, currentDate)) {
        sessionStorage.setItem('on-the-hour', currentDate.toISOString());
    }
}

function urlWithHour(urlString) {
    const url = new URL(urlString);
    url.searchParams.set('hour', new Date().getHours());
    return url.toString();
}

// Run any necessary project specific code outside the iframe
function projectSpecificOpen() {
    // let backgroundColors = ["#E5F3FF", "#BCDFFF", "#5FB2FF", "#01305B"]
    const el = document.body;
    const initialBackground = getComputedStyle(el).getPropertyValue('--color-background');
    const initialText = getComputedStyle(el).getPropertyValue('--color-text');
    el.style.setProperty('--color-background', '#DAF8FF');
    el.style.setProperty('--color-text', '#000000');

    // Legend
    const legend = document.querySelector('.on-the-hour-legend');
    legend?.style.setProperty('--color-background', initialBackground);
    legend?.style.setProperty('--color-text', initialText);

    if (ignoreUserPreferences()) {

    } else {

    }

    // Content (dim media)
    const main = document.querySelector('main');
    if (main) {
        main.querySelectorAll('.image, .video-embed-wrapper, .video-wrapper').forEach((el) => {
            el.style.opacity = '0.5';
        });
    }
}

// Clean up any project specific code that ran outside the iframe
function projectSpecificClose() {
    const el = document.body;
    el.style.removeProperty('--color-background');
    el.style.removeProperty('--color-text');

    // Legend
    const legend = document.querySelector('.on-the-hour-legend');
    legend?.style.removeProperty('--color-background');
    legend?.style.removeProperty('--color-text');

    // Content (dim media)
    const main = document.querySelector('main');
    if (main) {
        main.querySelectorAll('.image, .video-embed-wrapper, .video-wrapper').forEach((el) => {
            el.style.opacity = null;
        });
    }
}

export default function setupOnTheHour() {
    const wrapper = document.querySelector('.on-the-hour');
    if (wrapper.dataset.initialized === 'true') {
        return;
    }
    wrapper.dataset.initialized = 'true'; // Only initialize once...

    const THIRTY_SECONDS = 30000;
    const shouldDisable = document.querySelector('meta[name="on-the-hour"]')?.getAttribute('content') === "false";
    const iframe = wrapper ? wrapper.querySelector('.on-the-hour__iframe') : null;
    const forceOnTheHour = document.location.search.match(/[?&]on-the-hour=(\w*)/);
    const url = wrapper?.dataset.url;
    let currentDate;
    let escapeListener;

    function onTheHourClose() {
        wrapper.close();

        // Empty the iframe
        if (iframe) {
            iframe.classList.remove('active', 'will-be-active');
            iframe.src = '';
        }
        wrapper.classList?.remove('reduced-motion');

        document.removeEventListener('keydown', escapeListener);

        projectSpecificClose();
        scheduleNextCheck();
    }

    // Load in the actual iFrame for the project + any extras
    function loadProject() {
        // Load and display the iframe content
        function loadCallback() {
            iframe.classList.add('active'); // Removes visibility hidden on the project

            projectSpecificOpen(); // Run any project specific code (e.g. changes to whitney.org)
            setSessionVariable(currentDate); // Update session storage with the current time
            setTimeout(onTheHourClose, THIRTY_SECONDS); // Let the project run for 30 seconds before closing
        }

        iframe.addEventListener('load', loadCallback, { once: true });
        wrapper.showModal();
        wrapper.focus();
        iframe.classList.add('will-be-active'); // Projects may need iframe size so first display but keep visibility hidden
        iframe.src = urlWithHour(url);

        // Analytics tracking (if enabled)
        if (useAnalytics) {
            dataLayer.push({
                event: 'artport',
                label: 'on_the_hour',
                value: currentDate.getHours(),
            });
        }
    }

    // Triggers the projects if conditions are met and schedules the next one
    function triggerOnTheHour(force = false) {
        if (shouldDisable) {
            return;
        }

        // Make sure elements are present
        if (wrapper && iframe) {
            currentDate = new Date();
            const storedTimestamp = sessionStorage.getItem('on-the-hour');
            const storedDate = storedTimestamp ? new Date(storedTimestamp) : null;

            // Only trigger if forced, or if within the first minute of the hour and:
            // - No stored date exists OR
            // - The stored date exists but is not within the first minute of the current hour
            // ...the goal is to prevent multiple rapid re-fires on refresh or link clicks
            const shouldTrigger = force ||
                (isWithinFirstMinute(currentDate) && (!storedDate || !isWithinFirstMinute(storedDate))) ||
                (storedDate && isSessionVariableOutOfSync(storedDate));

            if (shouldTrigger) {
                // Add escape key listener
                // escapeListener = (e) => {
                //     if (e.key === 'Escape') {
                //         e.preventDefault();
                //         onTheHourClose()
                //     }
                // };
                // document.addEventListener('keydown', escapeListener);

                // Reduced motion users get the option to trigger the project
                if (getReducedMotion() && !ignoreUserPreferences()) {
                    // Show the legend
                    // wrapper.classList.add('active', 'reduced-motion');
                    wrapper.classList.add('reduced-motion');
                    wrapper.showModal();
                    wrapper.focus();

                    // Set a reasonable delay for someone to read and trigger the project (or not)
                    setTimeout(() => {
                        // If the iframe is active it means the user triggered the project
                        // ...if it isn't we should remove the legend since it seems they don't want to
                        if (!iframe.src) {
                            onTheHourClose();
                        }
                    }, THIRTY_SECONDS);
                } else {
                    loadProject();
                }

            } else {
                // If no trigger, still schedule the next check
                scheduleNextCheck();
            }
        } else {
            // If wrapper or iframe is not present, still schedule the next check
            scheduleNextCheck();
        }
    }

    // Schedules the next check based on the time until the next top of the hour
    function scheduleNextCheck() {
        const now = new Date();

        // Get the top of the current hour
        let nextTopOfHour = getTopOfCurrentHour(now);

        // If the current time is past the top of the current hour, move to the next hour
        if (now.getTime() >= nextTopOfHour.getTime()) {
            nextTopOfHour.setHours(nextTopOfHour.getHours() + 1);
        }

        // Calculate the exact time difference to the next top of the hour
        const timeUntilNextHour = nextTopOfHour.getTime() - now.getTime();

        // Set a timeout to trigger exactly at the next top of the hour
        setTimeout(() => {
            triggerOnTheHour();
        }, timeUntilNextHour);
    }

    // Expose this to the window so we can arbitrarily call it elsewhere (including signage for testing)
    window.forceOnTheHour = () => {
        // Remove existing session variable so it will for sure trigger
        sessionStorage.removeItem('on-the-hour');
        triggerOnTheHour(true)
    }

    // Check for query triggers
    if (forceOnTheHour) {
        window.forceOnTheHour();
    } else {
        // Only trigger if within the first minute of the top of the hour
        if (isWithinFirstMinute()) {
            triggerOnTheHour();
        } else {
            scheduleNextCheck(); // If not within the first minute, just schedule the next check
        }
    }

    wrapper.addEventListener('close', () => {
        // Your custom logic here
        onTheHourClose();
    });


    // Allow for manual triggering for people with reduced motion on
    wrapper.querySelectorAll('.on-the-hour-legend__trigger').forEach((el) => {
        el.addEventListener('click', () => {
            wrapper.classList.remove('reduced-motion');
            loadProject();
        });
    });

    // Handle clicking any close button
    wrapper.querySelectorAll('.on-the-hour-legend__x').forEach((el) => {
        el.addEventListener('click', () => {
            wrapper.close();
        });
    });
}
